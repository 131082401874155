import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import { getFormatedDate } from "../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import {
  IsSuperAdmin,
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  statesMessageContent,
} from "../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import {
  CareEmployeePermissionConstants,
  CareRecipientPermissionConstants,
} from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFButtonConstant,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { viewUploadedDocumentFile } from "../../ActiveContacts/Document/Library/DocumentHelper";
import ChecklistItemTransDetailsContent from "./ChecklistItemTransDetailsContent";
import {
  ChecklistItemTransSaveRequest,
  RAFChecklistItemTransStatus,
  getChecklistItemTransListByRelatedToUID,
  saveListchecklistTemplateItems,
} from "./ChecklistItemTransHelper";
import ChecklistItemTransManageUploadDocumentFile from "./ChecklistItemTransManageUploadDocumentFile";
import { ChecklistItemTransRow } from "./ChecklistItemTransRow";

interface IProps {
  relatedUID?: string;
  relatedToType?: string;
  checklistItems?: LookUpRow[];
  displayMode?: boolean;
}

interface IState {
  checklistItemTransRow: ChecklistItemTransRow;
  showUploadContent: boolean;
  showChecklistItemTransDetailsDlgContent: boolean;
  isChecklistItemTransUpdated: boolean;
}

function ChecklistItemTransDocument({
  relatedToType,
  relatedUID,
  checklistItems,
  displayMode,
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      checklistItemTransRow: null,
      showUploadContent: false,
      showChecklistItemTransDetailsDlgContent: false,
      isChecklistItemTransUpdated: false,
    }
  );
  let uploadCompliancePermissionName = null;
  let manageCompliancePermissionName = null;
  let approveCompliancePermissionName = null;
  let commentCompliancePermissionName = null;

  if (relatedToType === RAFEntityName.Employee) {
    uploadCompliancePermissionName =
      CareEmployeePermissionConstants.EmployeeUploadComplianceDocument;
    manageCompliancePermissionName =
      CareEmployeePermissionConstants.EmployeeDeleteDocuments;
    approveCompliancePermissionName = CareEmployeePermissionConstants.EmployeeApproveComplianceDocument;
    commentCompliancePermissionName = CareEmployeePermissionConstants.EmployeeCommentComplianceDocument;
  } else if (relatedToType === CareEsioEntity.CareRecipient.EntityName) {
    uploadCompliancePermissionName =
      CareRecipientPermissionConstants.CareRecipientUploadComplianceDocument;
    manageCompliancePermissionName =
      CareRecipientPermissionConstants.CareRecipientManageCompliance;
    approveCompliancePermissionName = CareRecipientPermissionConstants.CareRecipientApproveComplianceDocument;
    commentCompliancePermissionName = CareRecipientPermissionConstants.CareRecipientCommentComplianceDocument;
  }

  const moduleName = CareEsioEntity.ChecklistItemTrans.EntityName;

  useEffect(() => {
    refresh();
  }, [relatedUID]);

  const refresh = async () => {
    setState({
      checklistItemTransRow: null,
      showUploadContent: false,
      showChecklistItemTransDetailsDlgContent: false,
      isChecklistItemTransUpdated: false,
    });
    let relatedToName = null;
    if (relatedToType === CareEsioEntity.CareRecipient.EntityName) {
      let clientRecord = await RetrieveRecord(
        relatedUID,
        relatedToType,
        null,
        null,
        "CareRecipient/RetrieveForm"
      );
      if (
        isNotNullAndUndefined(clientRecord) &&
        isNotNullAndUndefined(clientRecord.UID)
      ) {
        relatedToName = clientRecord["recipient_name"];
      }
    } else if (relatedToType === RAFEntityName.Employee) {
      let employeeRecord = await RetrieveRecord(
        relatedUID,
        relatedToType,
        null,
        null,
        "CareRecipient/RetrieveForm"
      );
      if (
        isNotNullAndUndefined(employeeRecord) &&
        isNotNullAndUndefined(employeeRecord.UID)
      ) {
        relatedToName = employeeRecord["recipient_name"];
      }
    }

    if (isNotNullAndUndefined(checklistItems)) {
      const checklistItemTransSaveRequest = new ChecklistItemTransSaveRequest();
      checklistItemTransSaveRequest.RelatedTo = relatedToName ?? "";
      checklistItemTransSaveRequest.RelatedToUID = relatedUID;
      checklistItemTransSaveRequest.RelatedToType = relatedToType;
      checklistItemTransSaveRequest.ChecklistItems = checklistItems;

      const checklistItemTransItems: ChecklistItemTransRow[] =
        await getChecklistItemTransListByRelatedToUID(relatedUID, null);

      if (isNotEmptyArray(checklistItemTransItems)) {
        let checklistItemTransRow = checklistItemTransItems.find(
          (x) => x.ChecklistTemplateItemUID === checklistItems[0].UID
        );
        if (isNotNullAndUndefined(checklistItemTransRow)) {
          setState({ checklistItemTransRow });
        } else {
          const response = await saveListchecklistTemplateItems(
            checklistItemTransSaveRequest
          );
          const checklistItemTransItems: ChecklistItemTransRow[] =
            await getChecklistItemTransListByRelatedToUID(relatedUID, null);
          let checklistItemTransRow =
            checklistItemTransItems &&
            checklistItemTransItems.find(
              (x) => x.ChecklistTemplateItemUID === checklistItems[0].UID
            );
          if (isNotNullAndUndefined(checklistItemTransRow)) {
            setState({ checklistItemTransRow });
          }
        }
      } else {
        const response = await saveListchecklistTemplateItems(
          checklistItemTransSaveRequest
        );
        const checklistItemTransItems: ChecklistItemTransRow[] =
          await getChecklistItemTransListByRelatedToUID(relatedUID, null);
        let checklistItemTransRow =
          checklistItemTransItems &&
          checklistItemTransItems.find(
            (x) => x.ChecklistTemplateItemUID === checklistItems[0].UID
          );
        if (isNotNullAndUndefined(checklistItemTransRow)) {
          setState({ checklistItemTransRow });
        }
      }
    }
  };

  //upload new document start
  const onClickUploadItem = (checklistItemTransRow: ChecklistItemTransRow) => {
    setState({ showUploadContent: true, checklistItemTransRow });
  };

  const uploadAndLinkDocumentContent = () => {
    if (state.showUploadContent) {
      const { checklistItemTransRow } = state;
      return (
        <ChecklistItemTransManageUploadDocumentFile
          onSave={() => onUploadDocumentFile()}
          onClose={() => uploadDialogClose()}
          relatedToUID={checklistItemTransRow.UID}
          relatedTo={checklistItemTransRow.Title}
          relatedEntity={moduleName}
          relatedToType={moduleName}
          selectedDocumentUID={checklistItemTransRow.DocumentUID}
          mode={
            isNotNullAndUndefined(checklistItemTransRow.DocumentUID)
              ? "updateVersion"
              : "create"
          }
          createPermissionName={uploadCompliancePermissionName}
          updatePermissionName={uploadCompliancePermissionName}
          hasExpiryDateRequired={checklistItemTransRow.HasExpiryDate}
          deadlineInterval={checklistItemTransRow.DeadlineInterval}
          deadlineUnits={checklistItemTransRow.DeadlineUnits}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const onUploadDocumentFile = () => {
    refreshOnUpdate();
  };

  const uploadDialogClose = () => {
    setState({ showUploadContent: false });
  };

  const getDocumentPreviewContent = (
    checklistItemTransItem: ChecklistItemTransRow
  ) => {
    if (isNotNullAndUndefined(checklistItemTransItem.DocumentUID)) {
      return (
        <div className="w-100">
          <div
            className="row gx-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="col-auto">
              <RAFButtonComponent
                isPrimary
                action={RAFButtonConstant.Preview}
                onClick={() => handlePreviewClick(checklistItemTransItem)}
                className="btn_state_success outline new_style raf_sm"
                iconBtn={BrowserIsDevice}
              />
            </div>
          </div>
        </div>
      );
    } else if (
      (checklistItemTransItem.AllowUserUpload || IsSuperAdmin()) &&
      displayMode === false
    ) {
      const btnAction =
        checklistItemTransItem.Status ===
          RAFChecklistItemTransStatus.NotStarted ||
          checklistItemTransItem.Status ===
          RAFChecklistItemTransStatus.NotUploaded
          ? RAFButtonConstant.Upload
          : RAFButtonConstant.Reupload;
      return (
        <div className="w-100">
          <div
            className="row gx-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="col-auto">
              <RAFButtonComponent
                isPrimary
                action={btnAction}
                onClick={() => onClickUploadItem(checklistItemTransItem)}
                className="btn_brand_primary outline new_style raf_sm"
                iconBtn={BrowserIsDevice}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handlePreviewClick = async (
    checklistItemTransItem: ChecklistItemTransRow
  ) => {
    let progressDiv = showProgress("body");
    const contentLibraryRow = await RetrieveRecord(
      checklistItemTransItem.DocumentUID,
      RAFEntityName.ContentLibrary
    );
    hideProgress(progressDiv);
    const response = await viewUploadedDocumentFile(contentLibraryRow);
  };

  //upload new document end

  const refreshOnUpdate = () => {
    refresh();
  };

  //details dlg content start
  const onClickDetailsChecklistItemTrans = (
    checklistItemTransRow: ChecklistItemTransRow
  ) => {
    setState({
      showChecklistItemTransDetailsDlgContent: true,
      isChecklistItemTransUpdated: false,
      checklistItemTransRow,
    });
  };

  const checklistItemTransDetailsDlgContent = () => {
    if (state.showChecklistItemTransDetailsDlgContent) {
      return (
        <ChecklistItemTransDetailsContent
          onDeleteChecklistItemTrans={refreshOnUpdate}
          onSaveChecklistItemTrans={onSaveChecklistItemTrans}
          objectUID={state.checklistItemTransRow.UID}
          uploadCompliancePermissionName={uploadCompliancePermissionName}
          manageCompliancePermissionName={manageCompliancePermissionName}
          approveCompliancePermissionName={approveCompliancePermissionName}
          commentCompliancePermissionName={commentCompliancePermissionName}
        />
      );
    } else {
      return null;
    }
  };

  const onSaveChecklistItemTrans = () => {
    setState({ isChecklistItemTransUpdated: true });
  };

  const closeChecklistItemTransDetailsDlgContent = () => {
    if (state.isChecklistItemTransUpdated) {
      refreshOnUpdate();
    } else {
      setState({ showChecklistItemTransDetailsDlgContent: false });
    }
  };
  //details dlg content end

  if (isNotNullAndUndefined(state.checklistItemTransRow)) {
    let checklistItemTransItem = state.checklistItemTransRow;
    let [statusMessage, statusClassName, formatedDate, showFormatedDate] =
      statesMessageContent(checklistItemTransItem.ExpiryDate);
    const status = checklistItemTransItem.DocumentStatus;

    return (
      <Fragment>
        <div
          className="col-12 pointer"
          onClick={(e) => {
            e.stopPropagation();
            if (displayMode) return;
            onClickDetailsChecklistItemTrans(checklistItemTransItem);
          }}
        >
          <CustomCardWidget
            footerTemplate={
              <div className="section__secondary__footer px-3">
                <RAFDetailsValueWithSeparator
                  removeEcllipse={[0, 1]}
                  outerClassName="h-auto"
                >
                  <RAFDetailFieldCustom
                    value={status}
                    displayValue={status}
                    title="DocumentStatus"
                    moduleName={CareEsioEntity.ChecklistItemTrans.EntityName}
                    isColorOption
                    mode="ringView"
                    field="DocumentStatus"
                    showLabel={false}
                  />
                  {(checklistItemTransItem.IsRequired ||
                    checklistItemTransItem.IsCompliance) && (
                      <div className="row gx-2 gy-0">
                        {checklistItemTransItem.IsRequired && (
                          <div className="col-auto">
                            <div className="raf_badge raf_xsm raf_badge_danger">
                              Required
                            </div>
                          </div>
                        )}
                        {checklistItemTransItem.IsCompliance && (
                          <div className="col-auto">
                            <div className="raf_badge raf_xsm raf_badge_primary">
                              Compliance
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                </RAFDetailsValueWithSeparator>
              </div>
            }
          >
            <div>
              <div className="row gx-2">
                <div className="col">
                  <div className="row gy-1 gx-0">
                    <div className="col-12">
                      <div className="subtitle_2">
                        {checklistItemTransItem.Title}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row gx-2">
                        <div className="col-auto">
                          <div className="body_3_light">Expiry date:</div>
                        </div>
                        <div className="col-auto">
                          <div className="body_3 medium">
                            {isNotNullAndUndefined(formatedDate) && (
                              <span className="content_brand_primary_base">
                                {formatedDate}
                              </span>
                            )}
                            {isNotNullAndUndefined(statusMessage) && (
                              <span className={`${statusClassName} ps-1`}>
                                {statusMessage}
                              </span>
                            )}
                          </div>
                        </div>
                        {isNotNullAndUndefined(
                          checklistItemTransItem.ReviewDate
                        ) && (
                            <>
                              <div className="col-auto">
                                <div className="body_3_light">Review Date:</div>
                              </div>
                              <div className="col-auto">
                                <div className="body_3 medium">
                                  <span className="content_brand_primary_base ps-1">
                                    {getFormatedDate(
                                      checklistItemTransItem.ReviewDate,
                                      MomentFormats.DATE
                                    )}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="col-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {getDocumentPreviewContent(checklistItemTransItem)}
                </div> */}
                {displayMode === false && (
                  <RAFPermissionRender
                    permissionName={manageCompliancePermissionName}
                  >
                    <div
                      className="col-auto"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <RAFButtonComponent
                        btnContent="View Details"
                        className="e-outline new_style raf_sm"
                        action={RAFButtonConstant.View}
                        iconBtn
                        onClick={() => {
                          onClickDetailsChecklistItemTrans(
                            checklistItemTransItem
                          );
                        }}
                      />
                    </div>
                  </RAFPermissionRender>
                )}
              </div>
            </div>
          </CustomCardWidget>
        </div>
        {state.showChecklistItemTransDetailsDlgContent && (
          <DialogComponent
            header={"Details"}
            showCloseIcon
            visible={state.showChecklistItemTransDetailsDlgContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_checklistTemplateItem_${moduleName}`}
            content={checklistItemTransDetailsDlgContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeChecklistItemTransDetailsDlgContent.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
          />
        )}
        {state.showUploadContent === true && (
          <DialogComponent
            header={
              isNotNullAndUndefined(checklistItemTransItem)
                ? checklistItemTransItem.Title
                : "Upload File"
            }
            showCloseIcon
            visible={state.showUploadContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            content={uploadAndLinkDocumentContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={uploadDialogClose.bind(this)}
            id="manageUploadDocumentDialog"
            zIndex={1300}
            open={PreventFocusOnDialogOpen}
          />
        )}
      </Fragment>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(ChecklistItemTransDocument);
