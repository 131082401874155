import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";

import { MenuItemModel } from "@syncfusion/ej2-navigations";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { SplitButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import { getFormatedDate } from "../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  IsSuperAdmin,
  IsSuperAdminQueryString,
  RetrieveRecord,
  getUserViewsByModuleName,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  getDocumentExpireDate,
  hexToRGBA,
  isNotEmptyArray,
  isNotNullAndUndefined,
  statesMessageContent,
} from "../../../RAFComponents/helpers/utils";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFButtonConstant,
  RAFLayout,
  RAFStatusNameWithColor,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { viewUploadedDocumentFile } from "../../ActiveContacts/Document/Library/DocumentHelper";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import ChecklistItemTransDetailsContent from "./ChecklistItemTransDetailsContent";
import {
  RAFChecklistItemTransStatus,
  generateChecklistItemTrans,
  getChecklistItemTransListByRelatedToUID,
  saveChecklistItemTransAPI,
} from "./ChecklistItemTransHelper";
import ChecklistItemTransManageUploadDocumentFile from "./ChecklistItemTransManageUploadDocumentFile";
import { ChecklistItemTransRow } from "./ChecklistItemTransRow";
import CreateChecklistItemTransDocument from "./CreateChecklistItemTransDocument";
import ChecklistItemTransListContent from "./ChecklistItemTransListContent";

interface IProps {
  relatedUID: string;
  relatedTo: string;
  relatedToType: string;

  readCompliancePermissionName: string;
  manageCompliancePermissionName: string;
  approveCompliancePermissionName: string;
  commentCompliancePermissionName: string;
  uploadCompliancePermissionName: string;
  deleteDocumentPermissionName: string;
}

interface IState {
  isLoading: boolean;
  isLoadingChecklistItemTrans: boolean;
  checklistItemTransItems: ChecklistItemTransRow[];
  selectedChecklistItemTransRow: ChecklistItemTransRow;

  showUploadContent: boolean;
  showChecklistItemTransDetailsDlgContent: boolean;
  isChecklistItemTransUpdated: boolean;

  showCreateChecklistItemTransDocContent: boolean;

  checklistItemTransTabItems: {
    viewName: string;
    viewUID: string;
  }[];
  selectedChecklistItemTransTabItem: {
    viewName: string;
    viewUID: string;
  };
}

function ChecklistItemTransListCardContent({
  ...props
}: PropsWithChildren<IProps>) {
  async function checkScrollable(text?) {
    const div = await document.querySelector(
      "#raf_tab_header_checklist_item_trans"
    );
    const leftButton = await document.getElementById(
      "btn_raf_tab_header_checklist_item_trans_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_tab_header_checklist_item_trans_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  const moduleName = CareEsioEntity.ChecklistItemTrans.EntityName;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      isLoadingChecklistItemTrans: true,
      checklistItemTransItems: null,
      selectedChecklistItemTransRow: null,

      showUploadContent: false,
      isChecklistItemTransUpdated: false,
      showChecklistItemTransDetailsDlgContent: false,
      showCreateChecklistItemTransDocContent: false,

      checklistItemTransTabItems: null,
      selectedChecklistItemTransTabItem: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.relatedUID]);

  const refresh = async () => {
    setState({
      isLoading: true,
      selectedChecklistItemTransRow: null,
      showChecklistItemTransDetailsDlgContent: false,
      showUploadContent: false,
      showCreateChecklistItemTransDocContent: false,
    });
    const viewMenuDataSource: RAFViewRow[] = await getUserViewsByModuleName(
      moduleName,
      true,
      true
    );

    // const checklistItemTransTabItems: { viewName: string; viewUID: string; }[] =
    //   isNotEmptyArray(viewMenuDataSource)
    //     ? viewMenuDataSource.map((x) => {
    //       return { viewName: x.DisplayName, viewUID: x.UID };
    //     })
    //     : null;
    let checklistItemTransTabItems: { viewName: string; viewUID: string }[] =
      [];
    if (isNotEmptyArray(viewMenuDataSource)) {
      viewMenuDataSource.sort((x, y) =>
        x.DisplayOrder > y.DisplayOrder ? 1 : -1
      );

      viewMenuDataSource.map((x) => {
        if (props.relatedToType === RAFEntityName.Employee) {
          if (x.ViewName.toLowerCase().includes("employee_compliance")) {
            checklistItemTransTabItems.push({
              viewName: x.DisplayName,
              viewUID: x.UID,
            });
          }
        } else if (
          props.relatedToType === CareEsioEntity.CareRecipient.EntityName
        ) {
          if (x.ViewName.toLowerCase().includes("client_compliance")) {
            checklistItemTransTabItems.push({
              viewName: x.DisplayName,
              viewUID: x.UID,
            });
          }
        }
      });
    }

    const selectedChecklistItemTransTabItem = isNotEmptyArray(
      checklistItemTransTabItems
    )
      ? checklistItemTransTabItems[0]
      : null;

    const checklistItemTransItems =
      await getChecklistItemTransListByRelatedToUID(
        props.relatedUID,
        isNotNullAndUndefined(selectedChecklistItemTransTabItem)
          ? selectedChecklistItemTransTabItem.viewUID
          : null
      );
    setState({
      isLoading: false,
      checklistItemTransItems,
      isLoadingChecklistItemTrans: false,
      checklistItemTransTabItems,
      selectedChecklistItemTransTabItem: isNotEmptyArray(
        checklistItemTransTabItems
      )
        ? checklistItemTransTabItems[0]
        : null,
    });
  };

  const refreshOnUpdate = async () => {
    setState({
      selectedChecklistItemTransRow: null,
      showChecklistItemTransDetailsDlgContent: false,
      showUploadContent: false,
      showCreateChecklistItemTransDocContent: false,
    });
    const { selectedChecklistItemTransTabItem } = state;
    const checklistItemTransItems =
      await getChecklistItemTransListByRelatedToUID(
        props.relatedUID,
        isNotNullAndUndefined(selectedChecklistItemTransTabItem)
          ? selectedChecklistItemTransTabItem.viewUID
          : null
      );
    setState({ isLoadingChecklistItemTrans: false, checklistItemTransItems });
  };

  //details dlg content start
  const onClickDetailsChecklistItemTrans = (
    selectedChecklistItemTransRow: ChecklistItemTransRow
  ) => {
    setState({
      showChecklistItemTransDetailsDlgContent: true,
      isChecklistItemTransUpdated: false,
      selectedChecklistItemTransRow,
    });
  };

  const checklistItemTransDetailsDlgContent = () => {
    if (state.showChecklistItemTransDetailsDlgContent) {
      return (
        <ChecklistItemTransDetailsContent
          onDeleteChecklistItemTrans={refreshOnUpdate}
          onSaveChecklistItemTrans={onSaveChecklistItemTrans}
          objectUID={state.selectedChecklistItemTransRow.UID}
          uploadCompliancePermissionName={props.uploadCompliancePermissionName}
          manageCompliancePermissionName={props.manageCompliancePermissionName}
          approveCompliancePermissionName={
            props.approveCompliancePermissionName
          }
          commentCompliancePermissionName={
            props.commentCompliancePermissionName
          }
        />
      );
    } else {
      return null;
    }
  };

  const onSaveChecklistItemTrans = () => {
    setState({ isChecklistItemTransUpdated: true });
  };

  const closeChecklistItemTransDetailsDlgContent = () => {
    if (state.isChecklistItemTransUpdated) {
      refreshOnUpdate();
    } else {
      setState({ showChecklistItemTransDetailsDlgContent: false });
    }
  };
  //details dlg content end

  const getStatusDiv = (
    checklistItemTransItem: ChecklistItemTransRow,
    uistyle?: "Badge" | "DotBadge"
  ) => {
    const status = checklistItemTransItem.Status;

    const colorCodeName = isNotNullAndUndefined(status)
      ? RAFStatusNameWithColor[status]
      : null;
    if (uistyle === "DotBadge") {
      return (
        <div className="row gx-2 align-items-center">
          <div className="col-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle
                cx="4"
                cy="4"
                r="4"
                fill={
                  isNotNullAndUndefined(colorCodeName)
                    ? hexToRGBA(colorCodeName.Color, 0.4)
                    : ""
                }
              />
              <circle
                cx="4"
                cy="4"
                r="2"
                fill={
                  isNotNullAndUndefined(colorCodeName)
                    ? colorCodeName.Color
                    : ""
                }
              />
            </svg>
          </div>
          <div className="col-auto body_3">
            <span
              className="body_3"
              style={{
                color: isNotNullAndUndefined(colorCodeName)
                  ? colorCodeName.Color
                  : "",
              }}
            >
              {isNotNullAndUndefined(colorCodeName)
                ? colorCodeName.DisplayName
                : isNotNullAndUndefined(status)
                ? status
                : "Status"}
            </span>
          </div>
        </div>
      );
    }
    return (
      <span
        className="raf_badge"
        style={{
          backgroundColor: isNotNullAndUndefined(colorCodeName)
            ? hexToRGBA(colorCodeName.Color, 0.1)
            : hexToRGBA("#333", 0.1),
          color: `${
            isNotNullAndUndefined(colorCodeName) ? colorCodeName.Color : "#333"
          }`,
          border: `1px solid ${hexToRGBA(
            isNotNullAndUndefined(colorCodeName) ? colorCodeName.Color : "#333",
            0.1
          )}`,
        }}
      >
        {isNotNullAndUndefined(colorCodeName)
          ? colorCodeName.DisplayName
          : isNotNullAndUndefined(status)
          ? status
          : "Status"}
      </span>
    );
  };

  //upload new document start
  const onClickUploadItem = (
    selectedChecklistItemTransRow: ChecklistItemTransRow
  ) => {
    setState({ showUploadContent: true, selectedChecklistItemTransRow });
  };

  const uploadAndLinkDocumentContent = () => {
    if (state.showUploadContent) {
      const { selectedChecklistItemTransRow } = state;
      return (
        <ChecklistItemTransManageUploadDocumentFile
          onSave={() => onUploadDocumentFile()}
          onClose={() => uploadDialogClose()}
          relatedToUID={selectedChecklistItemTransRow.UID}
          relatedTo={selectedChecklistItemTransRow.Title}
          relatedEntity={moduleName}
          relatedToType={moduleName}
          selectedDocumentUID={selectedChecklistItemTransRow.DocumentUID}
          mode={
            isNotNullAndUndefined(selectedChecklistItemTransRow.DocumentUID)
              ? "updateVersion"
              : "create"
          }
          createPermissionName={props.uploadCompliancePermissionName}
          updatePermissionName={props.uploadCompliancePermissionName}
          hasExpiryDateRequired={selectedChecklistItemTransRow.HasExpiryDate}
          deadlineInterval={selectedChecklistItemTransRow.DeadlineInterval}
          deadlineUnits={selectedChecklistItemTransRow.DeadlineUnits}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const onUploadDocumentFile = () => {
    refreshOnUpdate();
  };

  const uploadDialogClose = () => {
    setState({ showUploadContent: false });
  };
  //upload new document end

  //approve reject start
  const onMoreMenuClicked = (
    checklistItemTransRow: ChecklistItemTransRow,
    args: any
  ) => {
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.item)) {
      if (args.item.id === RAFButtonConstant.Approve.Id) {
        onClickApproveRejectBtn(
          checklistItemTransRow,
          RAFChecklistItemTransStatus.Approved
        );
      } else if (args.item.id === RAFButtonConstant.Reject.Id) {
        onClickApproveRejectBtn(
          checklistItemTransRow,
          RAFChecklistItemTransStatus.Rejected
        );
      }
    }
  };

  const onClickApproveRejectBtn = async (
    checklistItemTransRow: ChecklistItemTransRow,
    action:
      | RAFChecklistItemTransStatus.Rejected
      | RAFChecklistItemTransStatus.Approved
  ) => {
    let progressDiv = showProgress(
      "#checklistItemTransDetailsContent_outerDiv"
    );
    const submitChecklistItemTransFormValue = new ChecklistItemTransRow();
    submitChecklistItemTransFormValue.UID = checklistItemTransRow.UID;
    submitChecklistItemTransFormValue.Status = action;

    const checklistItemTransResponse = await saveChecklistItemTransAPI(
      submitChecklistItemTransFormValue
    );
    hideProgress(progressDiv);
    refreshOnUpdate();
  };
  //approve reject end

  const getApproveRejectContent = (
    checklistItemTransItem: ChecklistItemTransRow
  ) => {
    if (
      checklistItemTransItem.RequiresApproval &&
      checklistItemTransItem.Status ===
        // RAFChecklistItemTransStatus.Uploaded
        RAFChecklistItemTransStatus.Pending
    ) {
      let menuItems: MenuItemModel[] = [
        {
          id: RAFButtonConstant.Approve.Id,
          text: RAFButtonConstant.Approve.DisplayName,
          iconCss: RAFButtonConstant.Approve.IconCss,
        },
        {
          id: RAFButtonConstant.Reject.Id,
          text: RAFButtonConstant.Reject.DisplayName,
          iconCss: RAFButtonConstant.Reject.IconCss,
        },
      ];
      return (
        <>
          <div className="col-auto">
            <SplitButtonComponent
              key={checklistItemTransItem.UID}
              items={menuItems}
              content={RAFButtonConstant.Approve.DisplayName}
              select={(args) => onMoreMenuClicked(checklistItemTransItem, args)}
              onClick={() =>
                onClickApproveRejectBtn(
                  checklistItemTransItem,
                  RAFChecklistItemTransStatus.Approved
                )
              }
              cssClass="e-primary e-outline"
            ></SplitButtonComponent>
          </div>
        </>
      );
    } else if (
      checklistItemTransItem.RequiresApproval &&
      checklistItemTransItem.Status === RAFChecklistItemTransStatus.Approved
    ) {
      return (
        <>
          <div className="col-auto">
            <RAFButtonComponent
              isPrimary
              action={RAFButtonConstant.Reject}
              onClick={() =>
                onClickApproveRejectBtn(
                  checklistItemTransItem,
                  RAFChecklistItemTransStatus.Rejected
                )
              }
              className="btn_state_danger semi_dark new_style raf_sm"
              iconCss={RAFButtonConstant.Clear.IconCss}
            />
          </div>
        </>
      );
    } else if (
      checklistItemTransItem.RequiresApproval &&
      checklistItemTransItem.Status === RAFChecklistItemTransStatus.Rejected
    ) {
      return (
        <>
          <div className="col-auto">
            <RAFButtonComponent
              isPrimary
              action={RAFButtonConstant.Approve}
              onClick={() =>
                onClickApproveRejectBtn(
                  checklistItemTransItem,
                  RAFChecklistItemTransStatus.Approved
                )
              }
              className="btn_brand_primary semi_dark new_style raf_sm"
              iconCss={RAFButtonConstant.Complete.IconCss}
            />
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const getDocumentUploadContent = (
    checklistItemTransItem: ChecklistItemTransRow
  ) => {
    if (isNotNullAndUndefined(checklistItemTransItem.DocumentUID)) {
      return (
        <div className="w-100">
          <div
            className="row gx-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="col-auto">
              {getApproveRejectContent(checklistItemTransItem)}
            </div>
            {/* <div className="col-auto">
              <RAFButtonComponent
                isPrimary
                action={RAFButtonConstant.Preview}
                onClick={() => handlePreviewClick(checklistItemTransItem)}
                className="btn_state_success outline new_style raf_sm"
                iconBtn={BrowserIsDevice}
              />
            </div> */}
          </div>
        </div>
      );
    } else if (checklistItemTransItem.AllowUserUpload || IsSuperAdmin()) {
      const btnAction =
        checklistItemTransItem.Status ===
          RAFChecklistItemTransStatus.NotStarted ||
        checklistItemTransItem.Status ===
          RAFChecklistItemTransStatus.NotUploaded
          ? RAFButtonConstant.Upload
          : RAFButtonConstant.Reupload;
      return (
        <div className="w-100">
          <div
            className="row gx-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="col-auto">
              <RAFButtonComponent
                isPrimary
                action={btnAction}
                onClick={() => onClickUploadItem(checklistItemTransItem)}
                className="btn_brand_primary outline new_style raf_sm"
                iconBtn={BrowserIsDevice}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handlePreviewClick = async (
    checklistItemTransItem: ChecklistItemTransRow
  ) => {
    let progressDiv = showProgress("body");
    const contentLibraryRow = await RetrieveRecord(
      checklistItemTransItem.DocumentUID,
      RAFEntityName.ContentLibrary
    );
    hideProgress(progressDiv);
    const response = await viewUploadedDocumentFile(contentLibraryRow);
  };

  const checklistItemTransIsRequiredBadgeContent = (checklistItemTransItem) => {
    if (checklistItemTransItem.IsRequired)
      return <div className="raf_badge raf_xsm raf_badge_danger">Required</div>;
  };
  const checklistItemTransIsComplianceBadgeContent = (
    checklistItemTransItem
  ) => {
    if (checklistItemTransItem.IsCompliance)
      return (
        <div className="raf_badge raf_xsm raf_badge_primary">Compliance</div>
      );
  };

  const checklistItemTransAllowUserUploadContent = (checklistItemTransItem) => {
    let userUploadDisplayText = "Admin Upload";
    let userUploadDisplayStatus = "raf_badge_success";
    if (!checklistItemTransItem.AllowUserUpload && IsSuperAdmin()) {
      return (
        <div className={`raf_badge raf_xsm ${userUploadDisplayStatus}`}>
          {userUploadDisplayText}
        </div>
      );
    }
  };

  const checklistItemTransBadgeContent = (checklistItemTransItem) => {
    return (
      <RAFDetailsValueWithSeparator outerClassName="line-seprator h-auto">
        {getStatusDiv(checklistItemTransItem, "DotBadge")}
        {checklistItemTransItem.IsRequired &&
          !BrowserIsDevice &&
          checklistItemTransIsRequiredBadgeContent(checklistItemTransItem)}
        {checklistItemTransItem.IsCompliance &&
          !BrowserIsDevice &&
          checklistItemTransIsComplianceBadgeContent(checklistItemTransItem)}
        {!BrowserIsDevice &&
          checklistItemTransAllowUserUploadContent(checklistItemTransItem)}
      </RAFDetailsValueWithSeparator>
    );
  };

  function checklistTemplateItemCustomTitle(category: string) {
    return (
      <div
        id={category}
        className="d-flex align-items-center justify-content-between w-100 actionSection"
      >
        <div className={`row gx-2 gx-md-3`}>
          <div className="col d-flex align-items-center">
            <div className="row gx-0 gy-1">
              <div className="col-12 subtitle_1">
                <span className={`subtitle_1 semi_bold`}>{category}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Function to calculate the difference in days between today and the expiry date
  const calculateDaysDifference = (date) => {
    const today = new Date();
    const timeDifference = date.getTime() - today.getTime();
    return Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert time difference to days
  };

  const getChecklistItemTransItems = () => {
    if (state.isLoadingChecklistItemTrans === false) {
      if (isNotEmptyArray(state.checklistItemTransItems)) {
        const { checklistItemTransItems } = state;
        const groupedByCategory: {
          [category: string]: ChecklistItemTransRow[];
        } = checklistItemTransItems.reduce((acc, item) => {
          const category = item.Category || "Other"; // Assuming 'Category' is the property to group by. Use 'Other' or a similar placeholder for items without a category.
          acc[category] = acc[category] || [];
          acc[category].push(item);
          return acc;
        }, {});

        const sortedCategories = Object.keys(groupedByCategory).sort((a, b) => {
          if (a === "Other") return 1; // Ensure "Other" is always last
          if (b === "Other") return -1;
          return a.localeCompare(b); // Alphabetical sort for other categories
        });

        // Step 2: Render grouped items
        return sortedCategories.map((category) => {
          return (
            <CustomCardWidget
              cardClassName="overflow-hidden"
              removeContentPadding
              key={category}
            >
              <div>
                <RAFCollapseWithPlusIcon
                  toggleArrowIcon="Arrow"
                  customTitle={checklistTemplateItemCustomTitle(category)}
                  allowFullRowClick
                  isCollapsed={false}
                  collapsible={true}
                  layout={RAFLayout.OneColumnLayout}
                >
                  {groupedByCategory[category].map((checklistItemTransItem) => {
                    const expiredDateText = getDocumentExpireDate(
                      checklistItemTransItem.ExpiryDate
                    );
                    // The rest of your rendering logic remains the same for each item
                    let [
                      statusMessage,
                      statusClassName,
                      formatedDate,
                      showFormatedDate,
                    ] = statesMessageContent(checklistItemTransItem.ExpiryDate);
                    const status = checklistItemTransItem.DocumentStatus;
                    return (
                      <div
                        className="col-12 pointer"
                        key={checklistItemTransItem.UID}
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickDetailsChecklistItemTrans(
                            checklistItemTransItem
                          );
                        }}
                      >
                        <CustomCardWidget
                          footerTemplate={
                            <div className="section__secondary__footer px-3">
                              <RAFDetailsValueWithSeparator
                                removeEcllipse={[0, 1]}
                                outerClassName="h-auto"
                              >
                                <RAFDetailFieldCustom
                                  value={status}
                                  displayValue={status}
                                  title="DocumentStatus"
                                  moduleName={
                                    CareEsioEntity.ChecklistItemTrans.EntityName
                                  }
                                  isColorOption
                                  mode="ringView"
                                  field="DocumentStatus"
                                  showLabel={false}
                                />
                                {(checklistItemTransItem.IsRequired ||
                                  checklistItemTransItem.IsCompliance) && (
                                  <div className="row gx-2 gy-0">
                                    {checklistItemTransItem.IsRequired && (
                                      <div className="col-auto">
                                        <div className="raf_badge raf_xsm raf_badge_danger">
                                          Required
                                        </div>
                                      </div>
                                    )}
                                    {checklistItemTransItem.IsCompliance && (
                                      <div className="col-auto">
                                        <div className="raf_badge raf_xsm raf_badge_primary">
                                          Compliance
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </RAFDetailsValueWithSeparator>
                            </div>
                          }
                        >
                          <div>
                            <div className="row gx-2">
                              <div className="col">
                                <div className="row gy-1 gx-0">
                                  <div className="col-12">
                                    <div className="subtitle_2">
                                      {checklistItemTransItem.Title}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="row gx-2">
                                      <div className="col-auto">
                                        <div className="body_3_light">
                                          Expiry date:
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <div className="body_3 medium">
                                          {isNotNullAndUndefined(
                                            formatedDate
                                          ) && (
                                            <span className="content_brand_primary_base">
                                              {formatedDate}
                                            </span>
                                          )}
                                          {isNotNullAndUndefined(
                                            statusMessage
                                          ) && (
                                            <span
                                              className={`${statusClassName} ps-1`}
                                            >
                                              {statusMessage}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      {isNotNullAndUndefined(
                                        checklistItemTransItem.ReviewDate
                                      ) && (
                                        <>
                                          <div className="col-auto">
                                            <div className="body_3_light">
                                              Review Date:
                                            </div>
                                          </div>
                                          <div className="col-auto">
                                            <div className="body_3 medium">
                                              <span className="content_brand_primary_base ps-1">
                                                {getFormatedDate(
                                                  checklistItemTransItem.ReviewDate,
                                                  MomentFormats.DATE
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-auto"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {getDocumentUploadContent(
                                  checklistItemTransItem
                                )}
                              </div>
                              <RAFPermissionRender
                                permissionName={
                                  props.manageCompliancePermissionName
                                }
                              >
                                <div
                                  className="col-auto"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <RAFButtonComponent
                                    btnContent="View Details"
                                    className="e-outline new_style raf_sm"
                                    action={RAFButtonConstant.View}
                                    iconBtn
                                    onClick={() =>
                                      onClickDetailsChecklistItemTrans(
                                        checklistItemTransItem
                                      )
                                    }
                                  />
                                </div>
                              </RAFPermissionRender>
                            </div>
                          </div>
                        </CustomCardWidget>
                      </div>
                    );
                  })}
                </RAFCollapseWithPlusIcon>
              </div>
            </CustomCardWidget>
          );
        });
      } else {
        return (
          <RAFEmptyState
            iconClass="fas fa-memo"
            title="No Checklist Documents found"
          ></RAFEmptyState>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  };

  const onGenerateClick = async () => {
    let progressDiv = showProgress("body");
    const response = await generateChecklistItemTrans(
      props.relatedUID,
      props.relatedToType
    );
    hideProgress(progressDiv);
    refreshOnUpdate();
  };

  //upload new document start
  const onClickUploadChecklistItemTransDoc = () => {
    setState({ showCreateChecklistItemTransDocContent: true });
  };

  const onClickCreateChecklistItemTransDocContent = () => {
    if (state.showCreateChecklistItemTransDocContent) {
      return (
        <CreateChecklistItemTransDocument
          relatedTo={props.relatedTo}
          relatedToUID={props.relatedUID}
          relatedToType={props.relatedToType}
          onClose={uploadChecklistItemTransDocDialogClose}
          onSave={refreshOnUpdate}
          isActive
        />
      );
    } else {
      return null;
    }
  };

  const uploadChecklistItemTransDocDialogClose = () => {
    setState({ showCreateChecklistItemTransDocContent: false });
  };

  //upload new document end

  const uploadBtn = () => {
    return (
      <div className="row g-2 flex-nowrap" id="divHeaderButtons">
        <div className="col-auto">
          <RAFButtonComponent
            action={RAFButtonConstant.Add}
            className="btn_brand_primary semi_dark"
            onClick={onClickUploadChecklistItemTransDoc}
            isPrimary
            // {...BrowserIsDevice ? { iconBtn: true } : {}}
          />
        </div>
      </div>
    );
  };

  const generateBtn = () => {
    return (
      <div className="row g-2 flex-nowrap" id="divHeaderButtons">
        <div className="col-auto">
          <RAFButtonComponent
            btnContent="Generate"
            className="btn_brand_primary semi_dark"
            onClick={onGenerateClick}
            isPrimary
          />
        </div>
      </div>
    );
  };

  //tab content start

  const renderTabContent = () => {
    return <div className="row gy-2 gx-0">{getChecklistItemTransItems()}</div>;
  };

  const onSelectTabContent = async (selectedChecklistItemTransTabItem: {
    viewName: string;
    viewUID: string;
  }) => {
    setState({
      selectedChecklistItemTransTabItem,
      isLoadingChecklistItemTrans: true,
    });
    const checklistItemTransItems =
      await getChecklistItemTransListByRelatedToUID(
        props.relatedUID,
        isNotNullAndUndefined(selectedChecklistItemTransTabItem)
          ? selectedChecklistItemTransTabItem.viewUID
          : null
      );
    setState({ isLoadingChecklistItemTrans: false, checklistItemTransItems });
  };

  const viewUID = isNotNullAndUndefined(state.selectedChecklistItemTransTabItem)
    ? state.selectedChecklistItemTransTabItem.viewUID
    : null;

  const getTabContent = () => {
    if (state.isLoading === false) {
      const { checklistItemTransTabItems, selectedChecklistItemTransTabItem } =
        state;
      if (isNotEmptyArray(checklistItemTransTabItems)) {
        return renderTabContent();
      } else {
        return (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  };
  //tab content end

  return (
    <RAFPermissionRender permissionName={props.readCompliancePermissionName}>
      <div className="">
        <div className="section__primary__header raf_sm">
          <div className="w-100">
            <div className="row gx-2 align-items-center flex-nowrap">
              <div className="col overflow-auto">
                <div className="header_5">
                  <span>Compliance</span>
                </div>
              </div>
              <RAFPermissionRender
                permissionName={props.manageCompliancePermissionName}
              >
                <div className="col-auto">{uploadBtn()}</div>
              </RAFPermissionRender>
              {IsSuperAdminQueryString() && (
                <div className="col-auto">{generateBtn()}</div>
              )}
            </div>
          </div>
        </div>

        <ChecklistItemTransListContent
          entityDisplayName={
            props.relatedToType === RAFEntityName.Employee
              ? "Employee"
              : "Client"
          }
          relatedToUID={props.relatedUID}
          showHeader={false}
          manageCompliancePermissionName={props.manageCompliancePermissionName}
          uploadCompliancePermissionName={props.uploadCompliancePermissionName}
          approveCompliancePermissionName={
            props.approveCompliancePermissionName
          }
          commentCompliancePermissionName={
            props.commentCompliancePermissionName
          }
        />
        {false && (
          <>
            {BrowserIsDevice ? (
              <>
                <div className="section__primary__header raf_xsm ps-0">
                  {isNotNullAndUndefined(state.checklistItemTransTabItems) && (
                    <div
                      id="raf_tab_header_checklist_item_trans_container"
                      className="raf_tab_header raf_lg hideScrollBar"
                    >
                      {!BrowserIsDevice && (
                        <RAFButtonComponent
                          iconBtn
                          iconCss="fas fa-chevron-left"
                          className="bg-white border-0 custom-button-lg"
                          id="raf_tab_header_checklist_item_trans_left_scroll"
                          enableToolTip={false}
                        ></RAFButtonComponent>
                      )}
                      <div
                        id="raf_tab_header_checklist_item_trans"
                        className="raf_tab_header raf_lg hideScrollBar"
                      >
                        {state.checklistItemTransTabItems.map((x) => {
                          return (
                            <div
                              className={`raf_tab_header_item${
                                viewUID === x.viewUID ? " active" : ""
                              }`}
                              key={x.viewUID}
                              onClick={() => {
                                if (viewUID !== x.viewUID)
                                  onSelectTabContent(x);
                              }}
                            >
                              <span>{x.viewName}</span>
                            </div>
                          );
                        })}
                      </div>
                      {!BrowserIsDevice && (
                        <RAFButtonComponent
                          iconBtn
                          iconCss="fas fa-chevron-right"
                          className="bg-white border-0 custom-button-lg"
                          id="raf_tab_header_checklist_item_trans_right_scroll"
                          enableToolTip={false}
                        ></RAFButtonComponent>
                      )}
                    </div>
                  )}
                </div>
                <RAFEntityProvider moduleName={moduleName}>
                  <RAFAttributeRelatedListProvider moduleName={moduleName}>
                    <>
                      <div className={"detailspage-flex-content"}>
                        <div className="w-100 p-2 p-md-3 surface_neutral_base">
                          {getTabContent()}
                        </div>
                      </div>
                    </>
                  </RAFAttributeRelatedListProvider>
                </RAFEntityProvider>
              </>
            ) : (
              <ChecklistItemTransListContent
                entityDisplayName={
                  props.relatedToType === RAFEntityName.Employee
                    ? "Employee"
                    : "Client"
                }
                relatedToUID={props.relatedUID}
                showHeader={false}
                manageCompliancePermissionName={
                  props.manageCompliancePermissionName
                }
                uploadCompliancePermissionName={
                  props.uploadCompliancePermissionName
                }
                approveCompliancePermissionName={
                  props.approveCompliancePermissionName
                }
                commentCompliancePermissionName={
                  props.commentCompliancePermissionName
                }
              />
            )}
          </>
        )}
        {state.showChecklistItemTransDetailsDlgContent && (
          <DialogComponent
            header={"Details"}
            showCloseIcon
            visible={state.showChecklistItemTransDetailsDlgContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_checklistTemplateItem_${moduleName}`}
            content={checklistItemTransDetailsDlgContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeChecklistItemTransDetailsDlgContent.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
          />
        )}
        {state.showUploadContent === true && (
          <DialogComponent
            header={
              isNotNullAndUndefined(state.selectedChecklistItemTransRow)
                ? state.selectedChecklistItemTransRow.Title
                : "Upload File"
            }
            showCloseIcon
            visible={state.showUploadContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            content={uploadAndLinkDocumentContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={uploadDialogClose.bind(this)}
            id="manageUploadDocumentDialog"
            zIndex={1300}
            open={PreventFocusOnDialogOpen}
          />
        )}
        {state.showCreateChecklistItemTransDocContent === true && (
          <DialogComponent
            header={"Select Template"}
            showCloseIcon
            visible={state.showCreateChecklistItemTransDocContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            content={onClickCreateChecklistItemTransDocContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={uploadChecklistItemTransDocDialogClose.bind(this)}
            id="manageUploadDocumentDialog"
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
          />
        )}
      </div>
    </RAFPermissionRender>
  );
}

export default React.memo(ChecklistItemTransListCardContent);
