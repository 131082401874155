import React, {
  PropsWithChildren,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";

import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import moment from "moment";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFFieldStateProvider from "../../../RAFComponents/Grid/RAFFieldStateProvider";
import RAFGrid4 from "../../../RAFComponents/Grid/RAFGrid4";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  getUserViewsByModuleName,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender, {
  hasPermission,
} from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNotNullOrWhiteSpace,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
  setZeroHours,
} from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { RAFMatrixSelectedFilterRow } from "../../../RAFComponents/models/Common/ReportJM";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEmployeePermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  BrowserWidth,
  ContentType,
  RAFButtonConstant,
} from "../../../constants/Common/Constants";
import { RAFEntityType } from "../../../constants/Common/RMConstants";
import {
  ComplianceTableColumnTemplates,
  ComplianceTableColumnWidths,
} from "../../../helpers/ACRAFModuleGridColumnTemplates";
import { getURLPrefixByModuleName } from "../../../helpers/Common/URLPrefixByModuleName";
import RAFGridTemplates from "../../../helpers/RAFGridTemplates";
import { getUrlByModuleAndView } from "../../Common/List/IndexHelper";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import ChecklistItemTransDetailsContent from "./ChecklistItemTransDetailsContent";
import { ChecklistItemTransRow } from "./ChecklistItemTransRow";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";

interface IProps {
  uploadCompliancePermissionName: string;
  manageCompliancePermissionName: string;
  approveCompliancePermissionName: string;
  commentCompliancePermissionName: string;
  viewMenuDataSource?: RAFViewRow[];
  entityDisplayName?: string;
  matrixSelectedFilter?: RAFMatrixSelectedFilterRow;
  onDetailsBackClicked?: () => void;
  headerText?: string;
  relatedFilter?: RAFCustomFilter;
  showHeader?: boolean;
  relatedToUID?: string;
}

interface IState {
  isLoading: boolean;
  selectedChecklistItemTransRow: ChecklistItemTransRow;

  showChecklistItemTransDetailsDlgContent: boolean;

  checklistItemTransTabItems: {
    viewName: string;
    viewUID: string;
  }[];
  selectedChecklistItemTransTabItem: {
    viewName: string;
    viewUID: string;
  };
  gridContentKey: number;
  showMassupdateDialogContent: boolean;
  selectedGridItemsValue: any;
  viewMenuDataSource: RAFViewRow[];
}

function ChecklistItemTransListContent({
  ...props
}: PropsWithChildren<IProps>) {
  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  async function checkScrollable(text?) {
    const div = await document.querySelector(
      "#raf_tab_header_checklist_item_trans"
    );
    const leftButton = await document.getElementById(
      "btn_raf_tab_header_checklist_item_trans_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_tab_header_checklist_item_trans_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  const moduleName = CareEsioEntity.ChecklistItemTrans.EntityName;

  let deleteDialog: any;
  let archieveDialog: any;
  let revertDialog: any;
  let showHeader = isNotNullAndUndefined(props.showHeader)
    ? props.showHeader
    : true;
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      selectedChecklistItemTransRow: null,

      showChecklistItemTransDetailsDlgContent: false,

      checklistItemTransTabItems: null,
      selectedChecklistItemTransTabItem: null,
      gridContentKey: Math.random(),
      showMassupdateDialogContent: false,
      selectedGridItemsValue: null,
      viewMenuDataSource: null,
    }
  );

  const selectedGridItemsRef = useRef<Object[]>([]);

  useEffect(() => {
    refresh();
  }, [props.viewMenuDataSource, props.relatedToUID]);

  const refresh = async () => {
    setState({
      isLoading: true,
      selectedChecklistItemTransRow: null,
      showChecklistItemTransDetailsDlgContent: false,
    });

    let viewMenuDataSource;
    if (isNotEmptyArray(props.viewMenuDataSource)) {
      viewMenuDataSource = props.viewMenuDataSource;
    } else {
      viewMenuDataSource = await getUserViewsByModuleName(
        moduleName,
        true,
        true
      );
    }

    let checklistItemTransTabItems: { viewName: string; viewUID: string }[] =
      [];

    if (props.entityDisplayName === "Employee") {
      viewMenuDataSource = viewMenuDataSource.filter((x) =>
        x.ViewName.toLowerCase().includes("employee_compliance")
      );
    } else if (props.entityDisplayName === "Client") {
      viewMenuDataSource = viewMenuDataSource.filter((x) =>
        x.ViewName.toLowerCase().includes("client_compliance")
      );
    }

    if (
      isNotNullAndUndefined(props.matrixSelectedFilter) ||
      isNotNullAndUndefined(props.relatedFilter)
    ) {
      if (isNotEmptyArray(viewMenuDataSource)) {
        checklistItemTransTabItems.push({
          viewName: "All",
          viewUID: viewMenuDataSource[0].UID,
        });
      }
    } else {
      if (isNotEmptyArray(viewMenuDataSource)) {
        viewMenuDataSource.sort((x, y) =>
          x.DisplayOrder > y.DisplayOrder ? 1 : -1
        );
        // viewMenuDataSource.map((x) => {
        //   if (props.entityDisplayName === "Employee") {
        //     if (x.ViewName.toLowerCase().includes("employee_compliance")) {
        //       checklistItemTransTabItems.push({
        //         viewName: x.DisplayName,
        //         viewUID: x.UID,
        //       });
        //     }
        //   } else if (props.entityDisplayName === "Client") {
        //     if (x.ViewName.toLowerCase().includes("client_compliance")) {
        //       checklistItemTransTabItems.push({
        //         viewName: x.DisplayName,
        //         viewUID: x.UID,
        //       });
        //     }
        //   }
        // });
        viewMenuDataSource.map((x) => {
          checklistItemTransTabItems.push({
            viewName: x.DisplayName,
            viewUID: x.UID,
          });
        });
      }
    }

    setState({
      isLoading: false,
      checklistItemTransTabItems,
      selectedChecklistItemTransTabItem: isNotEmptyArray(
        checklistItemTransTabItems
      )
        ? checklistItemTransTabItems[0]
        : null,
      viewMenuDataSource,
    });
  };

  const refreshOnUpdate = async () => {
    setState({
      selectedChecklistItemTransRow: null,
      showChecklistItemTransDetailsDlgContent: false,
      gridContentKey: Math.random(),
    });
  };

  //details dlg content start

  const checklistItemTransDetailsDlgContent = () => {
    if (state.showChecklistItemTransDetailsDlgContent) {
      return (
        <ChecklistItemTransDetailsContent
          onDeleteChecklistItemTrans={refreshOnUpdate}
          onSaveChecklistItemTrans={refreshOnUpdate}
          objectUID={state.selectedChecklistItemTransRow.UID}
          uploadCompliancePermissionName={props.uploadCompliancePermissionName}
          manageCompliancePermissionName={props.manageCompliancePermissionName}
          approveCompliancePermissionName={
            props.approveCompliancePermissionName
          }
          commentCompliancePermissionName={
            props.commentCompliancePermissionName
          }
        />
      );
    } else {
      return null;
    }
  };

  const closeChecklistItemTransDetailsDlgContent = () => {
    setState({
      showChecklistItemTransDetailsDlgContent: false,
      selectedChecklistItemTransRow: null,
    });
    document.body.classList.remove("overflow-hidden");
  };
  //details dlg content end

  //tab content start

  const onSelectTabContent = async (selectedChecklistItemTransTabItem: {
    viewName: string;
    viewUID: string;
  }) => {
    setState({
      selectedChecklistItemTransTabItem,
    });
  };

  const viewUID = isNotNullAndUndefined(state.selectedChecklistItemTransTabItem)
    ? state.selectedChecklistItemTransTabItem.viewUID
    : null;

  const editClick = useCallback((item) => {
    document.body.classList.add("overflow-hidden");
    let divUpdateDialog = document.getElementById("updateRecordDialog");
    if (isNotNullAndUndefined(divUpdateDialog)) {
      divUpdateDialog.classList.remove("fade-in");
    }

    setState({
      selectedChecklistItemTransRow: item,
      showChecklistItemTransDetailsDlgContent: true,
    });
  }, []);

  const getAdditionalParams = (entity: EntityRow) => {
    let additionalParams;
    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntityName) &&
      entity.EntityType !== RAFEntityType.UserForm
    ) {
      additionalParams = [
        {
          key: "EntityName",
          value: entity.EntityName,
        },
      ];
    }
    return additionalParams;
  };

  const getAdditionalListFilter = (
    matrixSelectedFilter: RAFMatrixSelectedFilterRow,
    entityDisplayName: string,
    entity: EntityRow,
    queryAttributes: QueryAttributeJM[]
  ) => {
    let additionalListFilter: RAFCustomFilter;
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];
    if (
      isNotNullAndUndefined(entity) &&
      entity.EntityType === RAFEntityType.UserForm
    ) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];

      filterVal.push(entity.EntityName);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = "Entity";
      customFilter.Rules.push(filter);
    }

    if (
      isNotNullAndUndefined(matrixSelectedFilter) &&
      isNotNullAndUndefined(matrixSelectedFilter.Field) &&
      isNotNullAndUndefined(matrixSelectedFilter.Value)
    ) {
      const filterField = matrixSelectedFilter.Field;
      const queryAttributesField = isNotEmptyArray(queryAttributes)
        ? queryAttributes.find((x) => x.PropertyName === filterField)
        : null;
      const fieldDataType = isNotNullAndUndefined(queryAttributesField)
        ? queryAttributesField.DataType
        : null;

      if (
        fieldDataType === RAFDataType.Date ||
        fieldDataType === RAFDataType.DateTime
      ) {
        const startDate = setZeroHours(new Date(matrixSelectedFilter.Value));
        const endDate = setZeroHours(new Date(matrixSelectedFilter.Value));

        const dateFilterFiled = matrixSelectedFilter.Field;
        let evalue1Filter: RAFCustomFilter = {};
        let evalue1FilterVal: string[] = [];
        evalue1FilterVal.push(startDate as any); //28/12/2023 post  -- 27/12/2023/18:30:00 somthing greater than equal
        evalue1Filter.Operator = RAFCustomOperator.GreaterThanOrEqual;
        evalue1Filter.Value = evalue1FilterVal;
        evalue1Filter.Field = dateFilterFiled;
        customFilter.Rules.push(evalue1Filter);

        let evalue2Filter: RAFCustomFilter = {};
        let evalue2FilterVal: string[] = [];
        let nextDay = moment(endDate).add(1, "days").toDate();
        evalue2FilterVal.push(nextDay as any);
        evalue2Filter.Operator = RAFCustomOperator.LesserThan;

        evalue2Filter.Value = evalue2FilterVal;
        evalue2Filter.Field = dateFilterFiled;
        customFilter.Rules.push(evalue2Filter);
      } else {
        let filter4: RAFCustomFilter = {};
        let filterVal4: string[] = [];
        filterVal4.push(matrixSelectedFilter.Value);
        filter4.Operator = RAFCustomOperator.Equal;
        filter4.Value = filterVal4;
        filter4.Field = matrixSelectedFilter.Field;
        customFilter.Rules.push(filter4);
      }
    }

    if (isNotNullAndUndefined(props.relatedToUID)) {
      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(props.relatedToUID);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ChecklistItemTransRow>("RelatedToUID");
      customFilter.Rules.push(filter1);
    }

    if (isNotNullAndUndefined(props.relatedFilter)) {
      customFilter.Rules.push(props.relatedFilter);
    }

    if (IsNotNullOrWhiteSpace(entityDisplayName)) {
      let filter5: RAFCustomFilter = {};
      let filterVal5: string[] = [];
      filterVal5.push(entityDisplayName);
      filter5.Operator = RAFCustomOperator.Equal;
      filter5.Value = filterVal5;
      filter5.Field = propertyOf<ChecklistItemTransRow>("Entity");
      customFilter.Rules.push(filter5);
    }

    additionalListFilter = customFilter;

    return additionalListFilter;
  };

  function rowSelected(selectedRows: Object[]) {
    selectedGridItemsRef.current = selectedRows;
    const { selectedChecklistItemTransTabItem } = state;

    const { viewMenuDataSource } = state;

    const currentView =
      viewMenuDataSource &&
      viewMenuDataSource.find(
        (x) => x.UID === selectedChecklistItemTransTabItem.viewUID
      );
    const showDeleteButton =
      (currentView.ViewName.toLowerCase().includes("not_uploaded") ||
        currentView.ViewName.toLowerCase().includes("all")) &&
      isNotNullAndUndefined(selectedRows) &&
      selectedRows.length > 0
        ? true
        : false;

    const showArchieveButton =
      !currentView.ViewName.toLowerCase().includes("not_uploaded") &&
      !currentView.ViewName.toLowerCase().includes("all") &&
      !currentView.ViewName.toLowerCase().includes("compliance_archived") &&
      isNotNullAndUndefined(selectedRows) &&
      selectedRows.length > 0
        ? true
        : false;

    const showRevertButton =
      currentView.ViewName.toLowerCase().includes("compliance_archived") &&
      isNotNullAndUndefined(selectedRows) &&
      selectedRows.length > 0
        ? true
        : false;

    const divHeaderDeleteButton = document.querySelector(
      "#divHeaderDeleteButton"
    );
    if (divHeaderDeleteButton !== null) {
      if (showDeleteButton) {
        divHeaderDeleteButton.classList.remove("hidden");
      } else {
        divHeaderDeleteButton.classList.add("hidden");
      }
    }

    const divHeaderMassUpdateButton = document.querySelector(
      "#divHeaderMassUpdateButton"
    );
    if (divHeaderMassUpdateButton !== null) {
      if (showArchieveButton) {
        divHeaderMassUpdateButton.classList.remove("hidden");
      } else {
        divHeaderMassUpdateButton.classList.add("hidden");
      }
    }

    const divHeaderRevertButton = document.querySelector(
      "#divHeaderRevertButton"
    );
    if (divHeaderRevertButton !== null) {
      if (showRevertButton) {
        divHeaderRevertButton.classList.remove("hidden");
      } else {
        divHeaderRevertButton.classList.add("hidden");
      }
    }
  }

  //delete start
  function DeleteClicked(id?: string) {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No", cssClass: "form-custom-button" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: {
        text: "Yes",
        click: deleteRecord.bind(this, id),
        cssClass: "form-custom-button",
      },
      title: `Delete Compliance Document`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  }

  async function deleteRecord(recordId?: string) {
    let selectedRowIds: string[] = [];
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    let selectedGridItemsValue = selectedGridItemsRef.current;
    let objectId;

    if (isNotNullAndUndefined(recordId)) {
      objectId = recordId;
    } else if (
      isNotNullAndUndefined(selectedGridItemsValue) &&
      selectedGridItemsValue.length > 0
    ) {
      selectedGridItemsValue.forEach((item) => {
        selectedRowIds.push(item["UID"]);
      });

      objectId = selectedRowIds.toString();
    }

    let isDeleted = await DeleteRecord(objectId, moduleName);

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      refreshOnUpdate();
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong. Please try again later.");
      refreshOnUpdate();
    }
  }

  //delete end

  //massUpdate start

  function archieveClicked() {
    archieveDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No", cssClass: "form-custom-button" },
      closeOnEscape: false,
      content: "Are you sure want to archive?",
      okButton: {
        text: "Yes",
        click: archieveDocument.bind(this),
        cssClass: "form-custom-button",
      },
      title: `Archive Document`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog archiveDialog`,
    });
  }

  function archieveDocument() {
    let selectedRowIds: string[] = [];

    let selectedGridItemsValue = selectedGridItemsRef.current;
    if (
      isNotNullAndUndefined(selectedGridItemsValue) &&
      selectedGridItemsValue.length > 0
    ) {
      selectedGridItemsValue.forEach((item) => {
        selectedRowIds.push(item["UID"]);
      });

      const objRequest = getSaveRequest(
        { Status: "Archived" },
        selectedRowIds.join(",")
      );

      let progressDiv = showProgress(".archiveDialog.e-dialog");
      repositoryActions
        .postDataAndGetResponse(
          getURLPrefixByModuleName(moduleName) + "/MassUpdate",
          objRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          archieveDialog.hide();
          hideProgress(progressDiv);
          refreshOnUpdate();
        });
    }
  }

  function revertArchieveClicked() {
    revertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No", cssClass: "form-custom-button" },
      closeOnEscape: false,
      content: "Are you sure want to revert?",
      okButton: {
        text: "Yes",
        click: revertArchieveDocument.bind(this),
        cssClass: "form-custom-button",
      },
      title: `Revert Archived Document`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog revertDialog`,
    });
  }

  function revertArchieveDocument() {
    let selectedRowIds: string[] = [];

    let selectedGridItemsValue = selectedGridItemsRef.current;
    if (
      isNotNullAndUndefined(selectedGridItemsValue) &&
      selectedGridItemsValue.length > 0
    ) {
      selectedGridItemsValue.forEach((item) => {
        selectedRowIds.push(item["UID"]);
      });

      const objRequest = getSaveRequest(
        { Status: "Uploaded" },
        selectedRowIds.join(",")
      );

      let progressDiv = showProgress(".revertDialog.e-dialog");

      repositoryActions
        .postDataAndGetResponse(
          getURLPrefixByModuleName(moduleName) + "/MassUpdate",
          objRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          revertDialog.hide();
          hideProgress(progressDiv);
          refreshOnUpdate();
        });
    }
  }

  //massUpdate end

  const onActionMenuClicked = (selectedId, complianceDocument?: Object) => {
    switch (selectedId) {
      case "Delete":
        if (
          hasPermission(permissionValue, props.manageCompliancePermissionName)
        ) {
          DeleteClicked(complianceDocument["UID"]);
        } else {
          showWarningToast("You do not have permission to delete.");
        }
        break;
      default:
        break;
    }
  };

  const getTabContent = (
    entity: EntityRow,
    queryAttributes: QueryAttributeJM[]
  ) => {
    if (state.isLoading === false) {
      const { selectedChecklistItemTransTabItem } = state;

      const { viewMenuDataSource } = state;

      const currentView =
        viewMenuDataSource &&
        viewMenuDataSource.find(
          (x) => x.UID === selectedChecklistItemTransTabItem.viewUID
        );
      const moduleName = CareEsioEntity.ChecklistItemTrans.EntityName;

      return (
        <div key={currentView.UID} className="h-100">
          <RAFFieldStateProvider
            moduleName={moduleName}
            viewId={currentView.UID}
            //viewId={isNotNullAndUndefined(selectedTagNameStateValue) && isNotNullAndUndefined(selectedTagNameStateValue.TagName) ? null : currentView.UID}
            {...(showHeader === false ? { hideFields: ["RelatedTo"] } : {})}
          >
            <RAFGridTemplates
            //actionButtonClicked={onMoreMenuClicked}
            >
              <RAFGrid4
                url={getUrlByModuleAndView(moduleName, currentView)}
                //url={`${Constants.baseAPIUrl}DataList/List`}
                gridId={`grid_${moduleName}_${currentView.UID}`}
                moduleName={moduleName}
                isRemote
                onlineOffline={"Online"}
                gridTemplates={ComplianceTableColumnTemplates}
                {...(BrowserWidth > 450 && {
                  gridColumnWidth: ComplianceTableColumnWidths,
                })}
                allowSelection={true}
                rowClick={editClick}
                editClick={editClick}
                rowSelected={rowSelected}
                showEditColumn={false}
                allowEditing={false}
                actionButtonClicked={(id, complianceDocument) =>
                  onActionMenuClicked(id, complianceDocument)
                }
                disableFirstColumnTemplate
                emptyStateProps={{
                  title: "No results found.",
                  body: "Try adjusting your search or changing your filter to find what you're looking for!",
                  //image: nodata_es,
                }}
                viewId={currentView.UID}
                //filterBarVisibile={this.state.filterBarVisibility}
                filterBarVisibile={true}
                // toggleFilterFunction={(
                //     prop1: () => void
                // ) => {
                //     toggleFilterClick = prop1;
                // }}
                // {...(isNotNullAndUndefined(additionalListFilter)
                //   ? { additionalFilter: additionalListFilter }
                //   : {})}
                additionalParams={getAdditionalParams(entity)}
                additionalFilter={getAdditionalListFilter(
                  props.matrixSelectedFilter,
                  props.entityDisplayName,
                  entity,
                  queryAttributes
                )}
                isDynamic={true}
                // {...(isNotNullAndUndefined(getQueryString("filtertype")) && getQueryString("filtertype") === "qb"
                //   ? { filterType: "QueryBuilder" }
                //   : {})}
                // filterType="QueryBuilder"
                // allowFiltering

                // cssClass="hide-tableHeader ListGrid removeFirstRowMargin"
                // gridLines="Horizontal"
                // cssClass="raf_transparent_table firstColumnHeaderPadding"
                // isAdaptive={false}
              >
                {/* {getGridColumnsByModuleAndView(moduleName, currentView)} */}
              </RAFGrid4>
            </RAFGridTemplates>
          </RAFFieldStateProvider>
        </div>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  };
  //tab content end

  const { selectedChecklistItemTransTabItem, viewMenuDataSource } = state;

  let showRevertButton = false;
  let showArchieveButton = false;
  if (isNotNullAndUndefined(selectedChecklistItemTransTabItem)) {
    if (isNotNullAndUndefined(viewMenuDataSource)) {
      const currentView = viewMenuDataSource.find(
        (x) => x.UID === selectedChecklistItemTransTabItem.viewUID
      );
      showArchieveButton =
        currentView &&
        !currentView.ViewName.toLowerCase().includes("not_uploaded")
          ? true
          : false;
      showRevertButton =
        currentView &&
        currentView.ViewName.toLowerCase().includes("compliance_archived")
          ? true
          : false;
    }
  }
  // const currentView =
  //   viewMenuDataSource &&
  //   viewMenuDataSource.find(
  //     (x) => x.UID ===  selectedChecklistItemTransTabItem.viewUID
  //   );

  // const showArchieveButton =
  //   currentView && !currentView.ViewName.toLowerCase().includes("not_uploaded")
  //     ? true
  //     : false;

  // const showRevertButton =
  //   currentView &&
  //   currentView.ViewName.toLowerCase().includes("compliance_archived")
  //     ? true
  //     : false;

  return (
    <div className={`section__container`}>
      {showHeader && (
        <div className={"section__primary__header raf_xsm"}>
          <div className="d-flex align-items-center">
            <div
              className="header-text-sm pointer d-flex align-items-center"
              onClick={props.onDetailsBackClicked}
            >
              <span className="fas fa-arrow-left w-auto ms-0 pe-2"></span>
              <span>{props.headerText}</span>
            </div>
          </div>
        </div>
      )}
      <div className="section__primary__header raf_xsm">
        <>
          {isNotNullAndUndefined(state.checklistItemTransTabItems) && (
            <div
              id="raf_tab_header_checklist_item_trans_container"
              className="raf_tab_header raf_lg hideScrollBar"
            >
              {!BrowserIsDevice && (
                <RAFButtonComponent
                  iconBtn
                  iconCss="fas fa-chevron-left"
                  className="bg-white border-0 custom-button-lg"
                  id="raf_tab_header_checklist_item_trans_left_scroll"
                  enableToolTip={false}
                ></RAFButtonComponent>
              )}
              <div
                id="raf_tab_header_checklist_item_trans"
                className="raf_tab_header raf_lg hideScrollBar"
              >
                {state.checklistItemTransTabItems.map((x) => {
                  return (
                    <div
                      className={`raf_tab_header_item${
                        viewUID === x.viewUID ? " active" : ""
                      }`}
                      key={x.viewUID}
                      onClick={() => {
                        if (viewUID !== x.viewUID) onSelectTabContent(x);
                      }}
                    >
                      <span>{x.viewName}</span>
                    </div>
                  );
                })}
              </div>
              {!BrowserIsDevice && (
                <RAFButtonComponent
                  iconBtn
                  iconCss="fas fa-chevron-right"
                  className="bg-white border-0 custom-button-lg"
                  id="raf_tab_header_checklist_item_trans_right_scroll"
                  enableToolTip={false}
                ></RAFButtonComponent>
              )}
            </div>
          )}
        </>
        <div id="divHeaderButtons" className="row g-2 flex-nowrap">
          <div className="justify-content-end align-items-end w-100 ">
            <div className="row align-items-end g-2 flex-nowrap">
              {showArchieveButton && (
                <div id="divHeaderMassUpdateButton" className="col-auto hidden">
                  <RAFPermissionRender
                    permissionName={props.manageCompliancePermissionName}
                  >
                    <RAFButtonComponent
                      action={RAFButtonConstant.Archive}
                      className="primary-custom-button"
                      onClick={() => archieveClicked()}
                      idString={moduleName}
                    />
                  </RAFPermissionRender>
                </div>
              )}
              {showRevertButton && (
                <div id="divHeaderRevertButton" className="col-auto hidden">
                  <RAFPermissionRender
                    permissionName={props.manageCompliancePermissionName}
                  >
                    <RAFButtonComponent
                      action={RAFButtonConstant.Revert}
                      className="primary-custom-button"
                      onClick={() => revertArchieveClicked()}
                      idString={moduleName}
                    />
                  </RAFPermissionRender>
                </div>
              )}

              <div id="divHeaderDeleteButton" className="col-auto hidden">
                <RAFPermissionRender
                  permissionName={props.manageCompliancePermissionName}
                >
                  <RAFButtonComponent
                    action={RAFButtonConstant.Delete}
                    className="primary-custom-button"
                    onClick={() => DeleteClicked()}
                    idString={moduleName}
                  />
                </RAFPermissionRender>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`section__div`}>
        <div className="h-100">
          <RAFEntityProvider moduleName={moduleName}>
            <RAFAttributeRelatedListProvider moduleName={moduleName}>
              <RAFEntityContext.Consumer>
                {({ entity, collectionName }) => {
                  return (
                    <RAFAttributesContext.Consumer>
                      {({ queryAttributes }) => {
                        return (
                          <div className={"detailspage-flex-content h-100"}>
                            <div className="w-100 p-2 p-md-3 surface_neutral_base h-100">
                              {getTabContent(entity, queryAttributes)}
                            </div>
                          </div>
                        );
                      }}
                    </RAFAttributesContext.Consumer>
                  );
                }}
              </RAFEntityContext.Consumer>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
          {state.showChecklistItemTransDetailsDlgContent && (
            <DialogComponent
              header={"Details"}
              showCloseIcon
              visible={state.showChecklistItemTransDetailsDlgContent}
              cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
              id={`dlg_checklistTemplateItem_${moduleName}`}
              content={checklistItemTransDetailsDlgContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={closeChecklistItemTransDetailsDlgContent.bind(this)}
              zIndex={1200}
              open={PreventFocusOnDialogOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ChecklistItemTransListContent);
